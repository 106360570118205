import React, { useEffect } from 'react';
import './css/index.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getPrescriptionsRequest } from '../../../store/actions/prescriptions';
import SortableList from '../../../templates/SortableList';
import HeaderRow from './components/HeaderRow';
import ItemRow from './components/ItemRow';
import useSortTypes from './components/sortTypes';
import AddButton from '../../../components/AddButton';
import useTranslation from '../../../translations';

function Prescriptions() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const prescriptions = useSelector((store) => store.prescriptions.prescriptions);
  const prescriptionsLoading = useSelector((store) => store.prescriptions.prescriptionsLoading);

  const sortTypes = useSortTypes();

  const getPrescriptions = (data) => {
    dispatch(getPrescriptionsRequest(data));
  };

  const onAddClick = () => {
    navigate('/prescriptions/edit');
  };

  const onItemClick = (item) => {
    if (item.status === 'draft') {
      navigate(`/prescriptions/edit?prescription_id=${item?.id}`);
    } else {
      navigate(`/prescriptions/view?prescription_id=${item?.id}`);
    }
  };

  const addButton = () => (
    AddButton({ text: t('add_prescription_btn'), onClick: onAddClick })
  );

  const onActNumberClick = (ev, item) => {
    ev.stopPropagation();

    if (item['act-status'] === 'draft') {
      navigate(`/acts/edit?act_id=${item['act-id']}`);
    } else {
      navigate(`/acts/view?act_id=${item['act-id']}`);
    }
  };

  return (
    <div className="prescriptions_wrapper">
      <SortableList
        data={prescriptions}
        loading={prescriptionsLoading}
        requestData={getPrescriptions}
        sortTypes={sortTypes}
        withSorting
        HeaderRow={HeaderRow}
        renderItem={ItemRow}
        blockNotAdmin={false}
        TitleButton={addButton}
        onItemClick={onItemClick}
        additionalCLick={onActNumberClick}
      />
    </div>
  );
}

export default Prescriptions;
