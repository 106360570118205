import React, { useMemo, useRef, useState } from 'react';
import '../css/index.scss';
import moment from 'moment';
import { useSelector } from 'react-redux';
import MyButton from '../../../../templates/MyButton';
import useTranslation from '../../../../translations';
import MyModal from '../../../../templates/MyModal';
import acts from '../../Acts';
import Api from '../../../../Api';
import MyInput from '../../../../templates/MyInput';
import useInputState from '../../../../helpers/hooks/useInputState';
import CancelButton from '../../../../components/CancelButton';
import MyCheckbox from '../../../../templates/MyCheckbox';

function SigningAct({ act, goBack }) {
  const { t } = useTranslation();
  const inputRef = useRef(null);
  const [isUploadModalVisible, setUploadModalVisible] = useState(false);
  const [isShareModalVisible, setShareModalVisible] = useState(false);
  const [modalScreen, setModalScreen] = useState('pdf');
  const [additionalRecipients, setAdditionalRecipients] = useInputState();
  const [files, setFiles] = useState([]);
  const [allocUsers, setAllocUsers] = useState([]);
  const [loading, setLoading] = useState(false);

  const account = useSelector((store) => store.account.profile);

  const openUploadModal = () => {
    setUploadModalVisible(true);
  };
  const closeUploadModal = () => {
    if (!loading) {
      setFiles([]);
      setUploadModalVisible(false);
    }
  };

  const openShareModal = () => {
    setShareModalVisible(true);
  };
  const closeShareModal = () => {
    if (!loading) {
      setShareModalVisible(false);
      goBack();
    }
  };

  const changeModalScreen = (val) => {
    setModalScreen(val);
    if (files?.length) {
      setFiles([]);
    }
  };

  const chooseFileButtonClick = () => {
    inputRef?.current?.click();
  };

  const onFileChoose = (ev) => {
    setFiles((prev) => ([...prev, ...ev.target.files]));
  };

  const removeFile = (index) => {
    files.splice(index, 1);
    setFiles((prev) => [...prev]);
  };

  const notInform = () => {
    goBack();
  };

  const activeRecipientsCount = useMemo(() => {
    const mainRecipients = allocUsers.filter((item) => item.isChecked).length;
    const addRecipients = additionalRecipients.split('@').length - 1;
    return mainRecipients + addRecipients;
  }, [allocUsers, additionalRecipients]);

  const handleUsers = (user) => {
    user.isChecked = !user.isChecked;
    setAllocUsers([...allocUsers]);
  };

  const sendInforming = async () => {
    if (!activeRecipientsCount) {
      return;
    }

    const addresses = `${
      allocUsers
        .map((item) => (item.isChecked ? item['user-uid'] : ''))
        .filter((item) => item)
        .join(', ')}, ${additionalRecipients.trim()}`;

    const payload = {
      tasks: [
        {
          'doc-type': 'act',
          'doc-id': act.id,
        },
      ],
      addresses: addresses.trim(),
    };

    setLoading(true);
    const { data } = await Api.sendEmails(payload);
    setLoading(false);
    if (data.message === 'Ok') {
      closeShareModal();
    }
  };

  const save = async () => {
    if (!files?.length) {
      return;
    }

    const payload = {
      'act-id': act.id,
      title: '',
      'project-id': act['project-id'],
      'project-title': act['project-title'],
      'build-obj-title': act['build-obj-title'],
      'predpisan-id': act['predpisan-id'],
      'number-out': act['number-out'],
      status: 'accept',
    };

    const formData = new FormData();
    formData.append('body', JSON.stringify(payload));
    files.forEach((file, index) => {
      formData.append(`photo_${index}`, file);
    });

    setLoading(true);
    const { data } = await Api.makeActAccept(formData);
    setLoading(false);
    if (data.message === 'Ok') {
      const users = [];
      const { data: sign } = await Api.getAllocs({
        'filter-val': {
          'build-comp-id': act['build-comp-id'],
          'is-constructor': true,
          'project-id': act['project-id'],
        },
        limit: 100,
        offset: 0,
        'page-number': 0,
        'search-val': '',
      });
      users.push(...sign.items);

      const { data: agreement } = await Api.getAllocs({
        'filter-val': {
          'build-comp-id': act['build-comp-id'],
          'is-stroymanager': true,
          'project-id': act['project-id'],
        },
        limit: 100,
        offset: 0,
        'page-number': 0,
        'search-val': '',
      });
      users.push(...agreement.items);
      setAllocUsers(users);
      closeUploadModal();
      openShareModal();
    }
  };

  return (
    <div className="signing_wrapper">
      <div className="line" />
      <MyButton className="uploading_button" text={t('original_upload')} onClick={openUploadModal} />
      <MyModal isOpen={isUploadModalVisible} closeFunction={closeUploadModal} closeOnPressOutside={!loading}>
        <div className="sign_modal_wrapper">
          <div className="modal_header">
            <p className="modal_title">{t('original_upload')}</p>
            <div className="modal_close_button" onClick={closeUploadModal}>
              <img className="modal_close" src="/images/close_big.png" alt="Close" />
            </div>
          </div>
          <div className="modal_row">
            <MyButton className={`modal_nav_button ${modalScreen === 'pdf' && 'active'}`} textClassName={`modal_nav_text ${modalScreen === 'pdf' && 'active'}`} text="PDF" onClick={() => changeModalScreen('pdf')} />
            <MyButton className={`modal_nav_button ${modalScreen === 'pages' && 'active'}`} textClassName={`modal_nav_text ${modalScreen === 'pages' && 'active'}`} text={t('pages')} onClick={() => changeModalScreen('pages')} />
          </div>
          {modalScreen === 'pages' && <p className="modal_info">{t('modal_info')}</p>}

          <div className="modal_main">
            {files?.map((item, index) => (
              <div key={index} className="modal_row file_item" onClick={() => removeFile(index)}>
                {item.name}
                <img src="/images/pink_close.png" alt="X" className="file_close" />
              </div>
            ))}
          </div>

          <MyButton className="modal_choose_button" text={t('choose_file')} onClick={chooseFileButtonClick} />
          <input type="file" style={{ display: 'none' }} onChange={onFileChoose} ref={inputRef} multiple={modalScreen === 'pages'} accept={modalScreen === 'pdf' ? 'application/pdf' : 'image/jpeg'} />
          <div className="bottom_row modal_row">
            <MyButton className="modal_save_button" disabled={!files?.length} text={t('save')} onClick={save} loading={loading} />
            <img src="/images/warning.png" alt="W" className="warning_img" />
            <p className="warning_text">{t('warning_text')}</p>
          </div>
        </div>
      </MyModal>
      <MyModal isOpen={isShareModalVisible} closeFunction={closeShareModal} closeOnPressOutside={false}>
        <div className="sign_modal_wrapper share">
          <p className="modal_title margin_bottom">{t('informing')}</p>
          <p className="modal_info">{t('informing_info')}</p>

          <p className="modal_small_title">{t('recommended_recipients')}</p>
          <div className="modal_main">
            {allocUsers?.map((user, index) => (
              <div key={`${user.id}${index}`} className="modal_checkbox">
                <MyCheckbox onChange={() => handleUsers(user)} value={user.isChecked} label={`${user.fio} (${user.role}) ${user['user-uid']}`} labelClassName="modal_checkbox_label" />
              </div>
            ))}
          </div>

          <p className="modal_small_title">{t('additional_recipients')}</p>
          <MyInput infoClassName="modal_input_info" wrapperClassName="modal_input" value={additionalRecipients} onChange={setAdditionalRecipients} info={t('additional_rec_info')} placeholder="some@gmail.com second@mail.com" />

          <div className="bottom_row modal_row">
            <MyButton className="modal_save_button" disabled={!activeRecipientsCount} text={t('send')} onClick={sendInforming} loading={loading} />
            <CancelButton text={t('not_inform')} onClick={notInform} loading={loading} />
          </div>
        </div>
      </MyModal>
    </div>
  );
}

export default SigningAct;
