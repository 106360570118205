import React, { useEffect, useState } from 'react';
import '../css/index.scss';
import moment, { min } from 'moment';
import useTranslation from '../../../../translations';
import MyButton from '../../../../templates/MyButton';
import MyModal from '../../../../templates/MyModal';
import Api from '../../../../Api';
import LocalStorageServices from '../../../../helpers/LocalStorageServices';
import getAppointmentDateInfo from '../../../../helpers/getAppointmentDateInfo';
import MyLoading from '../../../../templates/MyLoading';
import { COLORS } from '../../../../helpers/constants';

function YourAppointmentItem({
  item, index, getRefreshedData, onItemClick,
}) {
  const { t } = useTranslation();
  const [difference, setDifference] = useState('');
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);

  const [deletingLoading, setDeletingLoading] = useState(false);
  const [gettingLoading, setGettingLoading] = useState(false);

  const countDifference = () => {
    const now = moment();
    const startDate = moment(item.date_time, 'DD.MM.YYYY HH.mm');

    const duration = moment.duration(now.diff(startDate));
    const days = Math.floor(Math.abs(duration.asDays()));
    const hours = Math.floor(Math.abs(duration.asHours())) % 24;
    const minutes = Math.ceil(Math.abs(duration.asMinutes())) % 60;

    let res = t('after');

    res += days ? ` ${days} ${t('days_short')}` : '';
    res += hours ? ` ${hours} ${t('hours_short')}` : '';
    res += minutes ? ` ${minutes} ${t('minutes_short')}` : '';

    setDifference(res);
  };

  const openDeleteModal = (ev) => {
    ev.stopPropagation();
    setIsDeleteModalVisible(true);
  };

  const closeDeleteModal = (ev) => {
    ev?.stopPropagation();
    setIsDeleteModalVisible(false);
  };

  const deleteAppointment = async (ev) => {
    try {
      ev.stopPropagation();
      setDeletingLoading(true);

      const { data } = await Api.deleteAppointment(item.id, item.sync_uuid);

      if (data?.message === 'Success') {
        closeDeleteModal();
        LocalStorageServices.deleteAppointment(item.id);
        getRefreshedData(true);
      }
    } catch (e) {
      console.log(e?.response?.data);
      console.warn(e, 'while deleting appointment');
    } finally {
      setDeletingLoading(false);
    }
  };

  useEffect(() => {
    (async () => {
      try {
        setGettingLoading(true);
        const { data } = await Api.refreshAppointment({ id: item.id, sync_uuid: item.sync_uuid });
        if (data?.length) {
          const newData = data[0];

          const now = moment();
          const date = moment(newData.date_time, 'DD.MM.YYYY HH.mm');

          const duration = moment.duration(now.diff(date));

          const dateIndex = Math.ceil(duration.asDays()) + 1;
          const newItem = {
            ...item,
            date_time: newData.date_time,
            status: newData.status,
            info: {
              ...item.info,
              date: newData.date_time.split((' '))[0],
              time: newData.date_time.split((' '))[1],
              dateInfo: getAppointmentDateInfo(date, dateIndex, t),
            },
          };
          LocalStorageServices.updateAppointment(item.id, newItem);
          getRefreshedData(true);
        }
      } catch (e) {
        console.warn(e, 'while getting single appointment');
      } finally {
        setGettingLoading(false);
      }
    })();
    countDifference();

    const timer = setInterval(() => {
      countDifference();
    }, 1000 * 60);

    return () => clearInterval(timer);
  }, []);

  return (
    <div className="my_appointment_item" onClick={() => onItemClick(item.id)}>
      {gettingLoading ? (
        <MyLoading color={COLORS.main} />
      ) : (
        <>
          <div className="difference">
            <p className="difference_text">{difference}</p>
          </div>
          <p className="simple_text">{`${item.info.dateInfo} ${item.info.time || ''}`}</p>
          <p className="simple_text ellipsis">{item.info.engineer.fio}</p>
          <p className="simple_text">{item.labor}</p>
          <div className="bottom_row">
            <div className="left_part">
              <img src="/images/appointment_tick.png" alt="V" className="tick_icon" />
              <p className="status_text">{t(item.status)}</p>
            </div>
            <MyButton
              className="bottom_button no_margin"
              textClassName="button_text"
              text={t('delete').toUpperCase()}
              onClick={openDeleteModal}
            />
          </div>
        </>
      )}

      {isDeleteModalVisible && (
        <MyModal isOpen={isDeleteModalVisible} closeFunction={closeDeleteModal}>
          <p className="appointment_delete_text">{t('appointment_delete_text')}</p>
          <p className="appointment_delete_info">{item.labor}</p>

          <div className="buttons_row_appointment_modal">
            <MyButton
              text={t('cancel')}
              onClick={closeDeleteModal}
              disabled={deletingLoading}
            />
            <MyButton
              text={t('delete')}
              onClick={deleteAppointment}
              loading={deletingLoading}
            />
          </div>
        </MyModal>
      )}
    </div>
  );
}

export default YourAppointmentItem;
