import React, { useEffect, useRef, useState } from 'react';
import './css/index.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import useTranslation from '../../../translations';
import SinglePageHeader from '../../../components/SinglePageHeader';
import {
  getSingleActRequest,
} from '../../../store/actions/acts';
import MyLoading from '../../../templates/MyLoading';
import { COLORS, ROLES } from '../../../helpers/constants';
import SigningAct from './components/SigningAct';
import PdfIframe from '../../../components/PdfIframe';
import { getLetterheadsRequest } from '../../../store/actions/letterheads';
import MyButton from '../../../templates/MyButton';
import Api from '../../../Api';
import requestAllData from '../../../helpers/requestAllData';

function View() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();
  const actId = searchParams.get('act_id');
  const [activeLetterhead, setActiveLetterhead] = useState();
  const [activePrescription, setActivePrescription] = useState();

  const role = useSelector((store) => store.account.role);

  const singleAct = useSelector((store) => store.acts.singleAct);
  const gettingSingleActLoading = useSelector((store) => store.acts.gettingSingleActLoading);
  const gettingSingleActError = useSelector((store) => store.acts.gettingSingleActError);
  const letterheads = useSelector((store) => store.letterheads.letterheads);

  const status = singleAct?.status;

  const goBack = () => {
    navigate(-1, { replace: true });
  };

  const editAct = () => {
    navigate(`/acts/edit?act_id=${singleAct?.id}`);
  };

  useEffect(() => {
    if (actId) {
      if (actId !== singleAct.id) {
        dispatch(getSingleActRequest({ 'filter-val': { id: actId } }));
      }
    }
  }, [actId]);

  useEffect(() => {
    dispatch(getLetterheadsRequest(requestAllData));
  }, []);

  useEffect(() => {
    if (letterheads?.items && singleAct?.id) {
      const letterhead = letterheads.items.find((item) => item.id === singleAct['stc-id']);
      if (letterhead) {
        setActiveLetterhead(letterhead);
      } else {
        setActiveLetterhead('');
      }
    }
  }, [letterheads, singleAct]);

  useEffect(() => {
    (async () => {
      if (singleAct?.['predpisan-id']) {
        const { data: prescription } = await Api.getSinglePrescription({ 'filter-val': { id: singleAct['predpisan-id'] } });
        if (prescription?.id) {
          setActivePrescription(prescription);
        }
      }
    })();
  }, [singleAct]);

  return (
    <div className="single_act_wrapper">
      <SinglePageHeader text={t('view_act')} />
      {gettingSingleActLoading ? <MyLoading color={COLORS.main} height={20} width={20} /> : gettingSingleActError ? (
        <p>
          {t('no_act_with_id')}
          {actId}
        </p>
      ) : (
        <div>
          <div className="view_info_row">
            <p className="view_info_text margin_right">
              {t('number')}
              :
            </p>
            <p className="view_info_text">{singleAct['number-out']}</p>
          </div>
          <div className="view_info_row">
            <p className="view_info_text margin_right">
              {t('status')}
              :
            </p>
            <p className="view_info_text">{t(singleAct.status)}</p>
          </div>
          {!['accept', 'accept-force', 'force-accept'].includes(singleAct?.status) && role === ROLES.stroymanager && (
            <div className="buttons_row">
              <MyButton className="repeat_button" text={t('edit')} onClick={editAct} />
            </div>
          )}
          {status === 'signing' && role === ROLES.stroymanager && <SigningAct act={singleAct} goBack={goBack} />}
          {activePrescription && (
            <PdfIframe document={{ ...singleAct, activePrescription }} activeLetterhead={activeLetterhead} documentType="act" />
          )}
        </div>
      )}

    </div>
  );
}

export default View;
