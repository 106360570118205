import { takeLatest, call, put } from 'redux-saga/effects';
import {
  GET_MY_ALLOCATIONS_FAIL,
  GET_MY_ALLOCATIONS_REQUEST,
  GET_MY_ALLOCATIONS_SUCCESS, SET_MY_SCHEDULE_FAIL,
  SET_MY_SCHEDULE_REQUEST,
  SET_MY_SCHEDULE_SUCCESS,
  GET_PROFILE_FAIL,
  GET_PROFILE_REQUEST,
  GET_PROFILE_SUCCESS,
  LOGIN_FAIL,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
} from '../actions/account';
import Api from '../../Api';
import LocalStorageServices from '../../helpers/LocalStorageServices';

export default function* watcher() {
  yield takeLatest(LOGIN_REQUEST, loginUser);
  yield takeLatest(GET_PROFILE_REQUEST, getProfile);
  yield takeLatest(GET_MY_ALLOCATIONS_REQUEST, getMyAllocations);
  yield takeLatest(SET_MY_SCHEDULE_REQUEST, setMySchedule);
}

function* loginUser(action) {
  try {
    const { data } = yield call(Api.login, action.payload);
    LocalStorageServices.setToken(data.token);

    const { data: profileData } = yield call(Api.getProfile);
    if (profileData?.is_guest === 1) {
      LocalStorageServices.removeToken();

      yield put({
        type: LOGIN_FAIL,
        payload: 'not_engineer_login_error',
      });
      return;
    }

    yield put({
      type: LOGIN_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.log(e, 'while logging in');
    console.log(e?.response?.data);
    yield put({
      type: LOGIN_FAIL,
      payload: 'login_error',
    });
  }
}

function* getProfile() {
  try {
    const { data } = yield call(Api.getProfile);

    yield put({
      type: GET_PROFILE_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.log(e, 'while getting profile');
    console.log(e?.response?.data);
    yield put({
      type: GET_PROFILE_FAIL,
      message: e?.message,
      payload: e?.response?.data,
    });
  }
}

function* getMyAllocations() {
  try {
    const { data } = yield call(Api.getMyAllocations);

    yield put({
      type: GET_MY_ALLOCATIONS_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.log(e, 'while getting my allocations');
    console.log(e?.response?.data);
    yield put({
      type: GET_MY_ALLOCATIONS_FAIL,
      message: e?.message,
      payload: e?.response?.data,
    });
  }
}

function* setMySchedule(action) {
  try {
    const { data } = yield call(Api.setMySchedule, action.payload);

    yield put({
      type: SET_MY_SCHEDULE_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.log(e, 'while setting my schedule');
    console.log(e?.response?.data);
    yield put({
      type: SET_MY_SCHEDULE_FAIL,
      message: e?.message,
      payload: e?.response?.data,
    });
  }
}
