import React, { useEffect, useState } from 'react';
import './css/index.scss';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { v4 } from 'uuid';
import AppointmentWrapper from './components/AppointmentWrapper';
import useTranslation from '../../../translations';
import MyButton from '../../../templates/MyButton';
import MyInput from '../../../templates/MyInput';
import useInputState from '../../../helpers/hooks/useInputState';
import Api from '../../../Api';
import LocalStorageServices from '../../../helpers/LocalStorageServices';
import { CHECKUP_STATUSES } from '../../../helpers/constants';

function AddAppointment() {
  // eslint-disable-next-line prefer-const
  let { lang } = useParams();
  if (!['en', 'ru'].includes(lang)) lang = 'ru';

  const singleAppointment = useSelector((store) => store.appointment.singleAppointment);
  const { t } = useTranslation(lang);
  const navigate = useNavigate();

  const [appoint, setAppoint, appointError, setAppointError] = useInputState();
  const [fio, setFio, fioError, setFioError] = useInputState();
  const [phone, setPhone, phoneError, setPhoneError] = useInputState();
  const [comment, setComment, commentError, setCommentError] = useInputState();

  const [savingLoading, setSavingLoading] = useState(false);
  const [reservedError, serReservedError] = useState(false);

  const onChangeClick = () => {
    navigate('..');
  };

  const onPlaceApplicationClick = async () => {
    let flag = false;

    if (!appoint) {
      setAppointError(t('appoint_error'));
      flag = true;
    }
    if (!fio) {
      setFioError(t('name_surname') + t('is_required_mn_ch'));
      flag = true;
    }
    if (!phone) {
      setPhoneError(t('phone') + t('is_required_m_r'));
      flag = true;
    }
    if (!comment) {
      setCommentError(t('note_error'));
      flag = true;
    }

    if (flag || !singleAppointment) {
      return;
    }

    const payload = {
      object_id: singleAppointment?.object_id,
      user_id: singleAppointment?.engineer?.user_id,
      date_time: `${singleAppointment?.date} ${singleAppointment?.time}`,
      labor: appoint,
      fio,
      contact: phone,
      descr: comment,
      sync_uuid: v4(),
      client_id: singleAppointment?.client_id,
    };

    try {
      setSavingLoading(true);
      const { data } = await Api.createAppointment(payload);

      if (data.id) {
        payload.id = data.id;
        payload.info = singleAppointment;
        payload.status = CHECKUP_STATUSES.requested;

        LocalStorageServices.appendAppointment(payload);
        navigate(`../view?appointment_my_id=${data.id}`);
      }
    } catch (e) {
      if (e?.response?.data?.error === 'POST Appointment Error: this time period is reserved') {
        serReservedError(true);
      }
      console.warn(e, 'while creating appointment');
    } finally {
      setSavingLoading(false);
    }
  };

  useEffect(() => {
    if (!singleAppointment.project_title) {
      navigate('..');
    }
  }, [singleAppointment]);

  return (
    <AppointmentWrapper>
      <p className="page_title">{t('appointment_record')}</p>

      <div className="simple_block">
        <p className="small_title">{t('organization')}</p>
        <p className="simple_text">{singleAppointment?.project_title}</p>
      </div>
      <div className="simple_block">
        <p className="small_title">{t('object')}</p>
        <p className="simple_text">{`${singleAppointment?.object_address} (${singleAppointment?.object_title})`}</p>
      </div>
      <div className="simple_block">
        <p className="small_title">{t('date_and_time')}</p>
        <div className="time_row">
          <div>
            <p className="simple_text">{singleAppointment?.time}</p>
            <p className="simple_text">{singleAppointment?.dateInfo}</p>
          </div>
          <MyButton
            text={t('change').toUpperCase()}
            className="time_button"
            textClassName="button_text"
            onClick={onChangeClick}
          />
        </div>
      </div>
      <div className="simple_block">
        <p className="small_title">{t('engineer')}</p>
        <p className="simple_text">{singleAppointment?.engineer?.fio}</p>
      </div>
      <MyInput
        label={t('what_to_appoint')}
        placeholder={t('what_to_appoint_placeholder')}
        value={appoint}
        onChange={setAppoint}
        error={appointError}
        wrapperClassName="margin_top"
      />
      <MyInput
        label={t('name_surname')}
        placeholder={t('name_surname_placeholder')}
        value={fio}
        onChange={setFio}
        error={fioError}
        wrapperClassName="margin_top"
      />
      <MyInput
        label={t('phone')}
        placeholder="+7 (999) 888-77-66"
        value={phone}
        onChange={setPhone}
        error={phoneError}
        wrapperClassName="margin_top"
      />
      <MyInput
        label={t('appointment_comment')}
        placeholder={t('appointment_comment_placeholder')}
        value={comment}
        onChange={setComment}
        error={commentError}
        wrapperClassName="margin_top"
        className="big_input"
        textarea
      />

      <MyButton
        text={t('place_an_application')}
        onClick={onPlaceApplicationClick}
        className="bottom_button"
        textClassName="button_text"
        loading={savingLoading}
        disabled={reservedError}
      />

      {reservedError && (
        <p className="error_text_red">{t('appointment_reserved_error')}</p>
      )}

    </AppointmentWrapper>
  );
}

export default AddAppointment;
