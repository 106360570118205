import React, { useEffect, useState } from 'react';
import './css/index.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import useTranslation from '../../../translations';
import SinglePageHeader from '../../../components/SinglePageHeader';
import useInputState from '../../../helpers/hooks/useInputState';
import MyInput from '../../../templates/MyInput';
import MyButton from '../../../templates/MyButton';
import CancelButton from '../../../components/CancelButton';
import {
  getObjectsRequest, saveObjectRequest,
  setSingleObject,
} from '../../../store/actions/objects';
import MySelect from '../../../templates/MySelect';
import SelectedCompanyItem from './components/SelectedCompanyItem';
import { getCompaniesRequest } from '../../../store/actions/companies';
import { getProjectsRequest } from '../../../store/actions/projects';
import SelectedProjectItem from './components/SelectedProjectItem';
import requestAllData from '../../../helpers/requestAllData';
import Api from '../../../Api';

function SingleObject() {
  const dispatch = useDispatch();
  const { t, lang } = useTranslation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const objectId = searchParams.get('object_id');

  const profile = useSelector((store) => store.account.profile);
  const objects = useSelector((store) => store.objects.objects);
  const singleObject = useSelector((store) => store.objects.singleObject);
  const saveLoading = useSelector((store) => store.objects.saveLoading);
  const saveError = useSelector((store) => store.objects.saveError);

  const companies = useSelector((store) => store.companies.companies);
  const projects = useSelector((store) => store.projects.projects);

  const [title, setTitle, titleError, setTitleError] = useInputState();
  const [address, setAddress, addressError, setAddressError] = useInputState();
  const [company, setCompany, companyError, setCompanyError] = useInputState();
  const [project, setProject, projectError, setProjectError] = useInputState();

  const [toSave, setToSave] = useState(false);

  useEffect(() => {
    if (!companies?.items) {
      dispatch(getCompaniesRequest(requestAllData));
    }
    if (!projects?.items) {
      dispatch(getProjectsRequest(requestAllData));
    }
    if (!objectId) {
      return;
    }
    if (!objects?.items) {
      dispatch(getObjectsRequest(requestAllData));
    }
  }, []);

  useEffect(() => {
    if (objectId && objects?.items) {
      dispatch(setSingleObject(objectId));
    }
  }, [objects]);

  useEffect(() => {
    if (objectId && singleObject?.id) {
      setTitle(singleObject?.title);
      setAddress(singleObject?.address);
      setCompany(singleObject?.build_comp_id);
      setProject(singleObject && singleObject['project-id']);
    }
  }, [singleObject]);

  useEffect(() => {
    if (toSave && !saveLoading) {
      if (!saveError) {
        goBack();
      } else {
        setToSave(false);
      }
    }
  }, [saveLoading]);

  const goBack = () => {
    navigate(-1, { replace: true });
  };

  const onSave = () => {
    let flag = false;
    if (!title) {
      setTitleError(t('title') + t('is_required_s_r'));
      flag = true;
    }

    if (!address) {
      setAddressError(t('address') + t('is_required_m_r'));
      flag = true;
    }
    if (!company) {
      setCompanyError(t('company') + t('is_required_j_r'));
      flag = true;
    }
    if (!project) {
      setProjectError(t('project') + t('is_required_m_r'));
      flag = true;
    }

    if (flag) {
      return;
    }

    const data = {
      'build-comp-id': company,
      'project-id': project,
      address,
      title,
    };

    if (objectId) {
      data.id = +objectId;
    }

    if (objectId) {
      dispatch(saveObjectRequest({ data }));
    } else {
      dispatch(saveObjectRequest({ data, save: true }));
    }
    setToSave(true);
  };

  return (
    <div className="single_object_wrapper">
      <SinglePageHeader text={objectId ? t('edit_object') : t('add_object')} />
      {objects?.items && objectId && !singleObject?.id ? (
        <p className="no_object">
          {t('no_object')}
          {objectId}
        </p>
      ) : (
        <>
          <MyInput wrapperClassName="single_object_input" label={t('title')} value={title} onChange={setTitle} error={titleError} />
          <MyInput wrapperClassName="single_object_input" label={t('address')} value={address} onChange={setAddress} error={addressError} />
          <MySelect wrapperClassName="single_object_input" label={t('general_contractor')} renderItem={SelectedCompanyItem} data={companies?.items} showKey="title" buttonText={t('choose_company')} value={company} onChange={setCompany} error={companyError} t={t} />
          <MySelect wrapperClassName="single_object_input" label={t('project')} readOnly={!!objectId} renderItem={SelectedProjectItem} data={projects?.items} showKey="title" buttonText={t('choose_project')} value={project} onChange={setProject} error={projectError} t={t} />
          <div className="appointment_register_row">
            <p>
              {t('appointment_reg')}
              :
            </p>
            {objectId && profile && (
              <a href={`${Api.url}/z/${profile['client-id']}/${objectId}/${lang}/`}>{`${Api.url}/z/${profile['client-id']}/${objectId}/${lang}/`}</a>
            )}
          </div>
          <div className="buttons_row">
            <MyButton loading={saveLoading} onClick={onSave} text={objectId ? t('edit_object_button') : t('add_object_button')} />
            <CancelButton loading={saveLoading} />
          </div>
        </>
      )}
    </div>
  );
}

export default SingleObject;
